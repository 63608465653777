"use client";
import React, { createContext, use, useContext } from "react";

import { usePathname } from "next/navigation";

import { api } from "@web/trpc/client";
import { useArray } from "@web/utils";

import { type Campaign } from "./ICampaign";
import { useStoreContext } from "../StoreProvider";

type CampaignsPath = {
  pathname?: string;
  campaignId?: string;
  outreachGroupId?: string;
  outreachId?: string;
};
export interface ICampaignContext {
  campaigns: Campaign[];
  setCampaigns: React.Dispatch<React.SetStateAction<Campaign[]>>;
  campaignsPath: CampaignsPath;
}

const CampaignContext = createContext<ICampaignContext | null>(null);

export function useCampaignContext() {
  const campaignContext = useContext(CampaignContext);

  if (!campaignContext) {
    throw new Error(
      "useCampaignContext must be used within a CampaignProvider"
    );
  }

  return campaignContext;
}

const CampaignProvider: React.FC<{
  children: React.ReactNode;
  initialCampaigns: Promise<Campaign[]>;
}> = ({ children, initialCampaigns }) => {
  const { currentStoreId } = useStoreContext();
  const { data } = api.campaign.getStoreCampaigns.useQuery(
    { storeId: currentStoreId },
    {
      initialData: use(initialCampaigns),
    }
  );
  const { value: campaigns, setValue: setCampaigns } = useArray<Campaign>(data);
  const pathname = usePathname();
  const campaignsPath: CampaignsPath = React.useMemo(() => {
    return pathname.startsWith("/campaigns")
      ? {
          pathname,
          campaignId: pathname.split("/")[2] ?? undefined,
          outreachGroupId: pathname.split("/")[3] ?? undefined,
          outreachId: pathname.split("/")[4] ?? undefined,
        }
      : {
          pathname: undefined,
          campaignId: undefined,
          outreachGroupId: undefined,
          outreachId: undefined,
        };
  }, [pathname]);
  const campaignContextValue = {
    campaigns: campaigns,
    setCampaigns: setCampaigns,
    campaignsPath: campaignsPath,
  };
  return (
    <CampaignContext.Provider value={campaignContextValue}>
      {children}
    </CampaignContext.Provider>
  );
};

export default CampaignProvider;
