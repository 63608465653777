import { env } from "@web/env";

export const getBaseUrl = () => {
  if (typeof window !== "undefined") return window.location.origin;
  if (env.VERCEL_URL) return `https://${env.VERCEL_URL}`;

  // eslint-disable-next-line no-restricted-properties
  return `http://localhost:${process.env.PORT ?? 3000}`;
};

export const getWebhooksUrl = () => {
  // env.VERCEL_URL is must start with https://
  if (env.VERCEL_URL) return `${env.VERCEL_URL}`;

  return env.WEBHOOKS_URL;
};

export const DEFAULT_PATHWAY_ID = "0bbd4733-027a-45e6-8ff2-d28f4a420d4d";
