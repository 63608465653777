"use client";
import React, { createContext, use, useContext } from "react";

import type { Store } from "@revana/db";

import { api } from "@web/trpc/client";
import { useArray } from "@web/utils";

export interface IStoreContext {
  stores: Store[];
  currentStoreId: number;
  setStores: React.Dispatch<React.SetStateAction<Store[]>>;
}

const StoreContext = createContext<IStoreContext | null>(null);

export function useStoreContext() {
  const storeContext = useContext(StoreContext);

  if (!storeContext) {
    throw new Error("useModelsContext must be used within a ModelsProvider");
  }

  return storeContext;
}

const StoreProvider: React.FC<{
  children: React.ReactNode;
  initialStores: Promise<Store[]>;
}> = ({ children, initialStores }) => {
  const { data } = api.store.getUserStores.useQuery(undefined, {
    initialData: use(initialStores),
  });
  const { value: stores, setValue: setStores } = useArray<Store>(data);

  const currentStoreId = stores?.[0]?.id ?? 0;

  const storeContextValue = {
    stores: stores,
    currentStoreId: Number(currentStoreId),
    setStores: setStores,
  };

  return (
    <StoreContext.Provider value={storeContextValue}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
